import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Image } from 'rebass';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "journey-mapping-for-jira"
    }}>{`Journey Mapping for Jira`}</h1>
    <br />
    <p>{`Journey Mapping for Jira enables you to write down your user or customer journeys within Jira and connect them to your work items.`}</p>
    <Image width={'80%'} src='/public/JM1.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`After installation, you will find a new sidebar item named "Journey Mapping", available to all users that have "Edit Issue" permissions within the project.`}</p>
    <p>{`This permission is needed because when associating issues with the journey map, the user needs to be able to edit the issue.`}</p>
    <h2 {...{
      "id": "overview"
    }}>{`Overview`}</h2>
    <p>{`Journey Mapping App enables you to
add an unlimited number of journey maps via the Overview page. `}<br />{`You can also search for them by name.`}</p>
    <p>{`You can also switch between Card view and List view here.`}</p>
    <Image width={'80%'} src='/public/JM2.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`By clicking on the journey map, you will be redirected to the editing canvas.`}</p>
    <h2 {...{
      "id": "editing"
    }}>{`Editing`}</h2>
    <p>{`Here, start with a rough outline of your user's journey map - then, go over it and put in more details over time.`}</p>
    <p>{`You can edit individual steps by double clicking on them - and you can also re-arrange them by dragging and dropping them. So don't worry when the
journey map isn't perfect from the start, you can always go back and amend it.`}</p>
    <Image width={'80%'} src='/public/JM3.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <p>{`When clicking the persona button, you can select an existing persona and associate it with the journey map, or create a new one.`}</p>
    <Image width={'80%'} src='/public/JM5.png' sx={{
      m: 4,
      ml: 0
    }} mdxType="Image" />
    <h2 {...{
      "id": "more-information"
    }}>{`More Information`}</h2>
    <p>{`For a step-by-step guide, read the `}<a parentName="p" {...{
        "href": "/journeymapping-example"
      }}>{`example`}</a>{`, which leads you through the creation process of a journey map.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      